import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit", "selectable" ]

  connect() {
    let checked = this.selectableTargets.some((st) => st.checked )
    if (checked) this.enableActionBtn()
    if (!checked) this.disableActionBtn()
  }

  goalSelected(event) {
    this.enableActionBtn()
    // if (['choose_monthly_payment', 'pay_off_by_date'].indexOf(event.params.goal) >= 0) {
    //   this.submitTarget.value = 'Continue'
    // } else {
    //   this.submitTarget.value = 'View results'
    // }
  }

  disableActionBtn() {
    this.submitTarget.disabled = true;
    this.submitTarget.value = "Please select a repayment goal.";
  }

  enableActionBtn() {
    this.submitTarget.disabled = false;
    this.submitTarget.value = "Continue";
  }
}

