import { Controller } from '@hotwired/stimulus'
import ClassList from './utils/ClassList'

export default class extends Controller {
  static targets = ['activatible', 'input']
  static values = { active: Boolean }

  toggle(event) {
    if (['checkbox', 'radio'].indexOf(event.target.getAttribute('type')) < 0) {
      event.preventDefault()
    }

    this.activeValue = !this.activeValue
  }

  activate(event) {
    if (['checkbox', 'radio'].indexOf(event.target.getAttribute('type')) < 0) {
      event.preventDefault()
    }

    this.activeValue = false
  }

  deactivate(event) {
    if (['checkbox', 'radio'].indexOf(event.target.getAttribute('type')) < 0) {
      event.preventDefault()
    }

    this.activeValue = true
  }

  activeValueChanged() {
    this.activatibleTargets.forEach(target => {
      let activeClasses = new ClassList(target.getAttribute('data-active-class'))
      let inactiveClasses = new ClassList(target.getAttribute('data-inactive-class'))

      if (this.activeValue) {
        activeClasses.add(target)
        inactiveClasses.remove(target)
      } else {
        activeClasses.remove(target)
        inactiveClasses.add(target)
      }

      if (this.inputTargets) {
        this.inputTargets.forEach(t => t.checked = this.activeValue)
      }
    })
  }
}