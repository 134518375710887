import { Controller } from "@hotwired/stimulus"
import cloneDeep from 'lodash/cloneDeep';

import TotalLoans from '../src/helpers/TotalLoanData/TotalLoans';
import StandardPaymentPlan from '../src/helpers/PaymentPlans/StandardPaymentPlan';
import RefiPaymentPlan from '../src/helpers/PaymentPlans/RefiPaymentPlan';
import IncomeBasedPaymentPlan from '../src/helpers/PaymentPlans/IncomeBasedPaymentPlan';
import IBRRepaymentSchedule from '../src/helpers/RepaymentSchedules/IBRRepaymentSchedule';
import ICRRepaymentSchedule from '../src/helpers/RepaymentSchedules/ICRRepaymentSchedule';
import PayeRepaymentSchedule from '../src/helpers/RepaymentSchedules/PayeRepaymentSchedule';
import RefiRepaymentSchedule from '../src/helpers/RepaymentSchedules/RefiRepaymentSchedule';
import RepayeRepaymentSchedule from '../src/helpers/RepaymentSchedules/RepayeRepaymentSchedule';
import StandardRepaymentSchedule from '../src/helpers/RepaymentSchedules/StandardRepaymentSchedule';

function calculateIncome(income, growth_percentage) {
  let value = income + (income * growth_percentage / 100 + 1)
  return value
}

const YEARLY_INFLATION = 1.03;
const TOTAL_YEARS = 26;
const JOINTLY = 'jointly';
const SEPARATELY = 'separately';

export default class extends Controller {
  static values = {
    form: Object,
    loans: Array
  }

  static targets = ["paymentsEstimateTable", "refinanceTable", "payeMfjTable", "payeMfsTable",
                    "repayeTable", "ibrMfsTable", "icrMfjTable", "icrMfsTable"]

  connect() {
    this.personalInfo = {
      isMarried: this.formValue.married,
      currentlyHaveChildren: this.formValue.dependentChildrenCount > 0,
      planningToHaveChildren: false,
      currentNumberOfChildren: this.formValue.dependentChildrenCount,
      plannedNumberOfChildren: 0,
      futureChildren: [],
    }

    let lastYearAgi = this.formValue.income
    let thisYearAgi = calculateIncome(lastYearAgi, this.formValue.annual_income_growth_percentage)
    let nextYearAgi = calculateIncome(thisYearAgi, this.formValue.annual_income_growth_percentage)
    this.incomeInfo = {
      lastYearAgi: lastYearAgi,
      thisYearAgi: thisYearAgi,
      nextYearAgi: nextYearAgi,
      spouseAgi: this.formValue.spouse_income,
    }

    this.loanInfo = {
      loans: [],
      spouseLoanBalance: 0,
      spouseLoanInterestRate: 0,
    };

    let spouseLoan = { spouseLoanBalance: 0 }
    this.loansValue.forEach(loan => {
      if (loan.loan_owner == "mine") {
        let formattedPersonalLoan = {
          type: loan.loan_type,
          principal: loan.total_balance,
          accruedInterest: 0,
          interest: loan.interest_rate
        }
        this.loanInfo.loans.push(formattedPersonalLoan)
      } else {
        spouseLoan.spouseLoanBalance += loan.total_balance
        spouseLoan.spouseLoanInterestRate = loan.interest_rate
      }
    });
    this.loanInfo.spouseLoanBalance = spouseLoan.spouseLoanBalance

    // Object that contains assumed 25 year plan. User can edit
    this.yearInfo = {
      yearPlan: [],
    };

    this.loanPaymentData = {
      payments: {
        standardPayments: [],
        refiPayments: [],
        payeMFJPayments: [],
        payeMFSPayments: [],
        repayePayments: [],
        ibrMFJPayments: [],
        ibrMFSPayments: [],
        icrMFJPayments: [],
        icrMFSPayments: []
      },
      repaymentSchedules: {
        standardRepaymentSchedule: {},
        refiRepaymentSchedule: {},
        payeMFSRepaymentSchedule: {},
        payeMFJRepaymentSchedule: {},
        repayeRepaymentSchedule: {},
        ibrMFSRepaymentSchedule: {},
        ibrMFJRepaymentSchedule: {},
        icrMFSRepaymentSchedule: {},
        icrMFJRepaymentSchedule: {}
      }
    };

    this.generateYearPlan()
    this.calculateAssumptions()
    this.displayStandardTable()
    this.displayRefinanceTable()
    if (this.personalInfo.isMarried) this.displayPayeMfjTable()
    this.displayPayeMfsTable()
    this.displayRepayeTable()
    this.displayIbrMfsTable()
    if (this.personalInfo.isMarried) this.displayIcrMfjTable()
    this.displayIcrMfsTable()
  }

  // Generates the payment plans and saves them to the state
  generatePayments = (currentInfo, totalLoanData, incomePlan) => {
    // STANDARD
    let standardPayments = new StandardPaymentPlan(currentInfo, totalLoanData);
    standardPayments.paymentPlan = standardPayments.calculatePayments();

    // REFI
    let refiPayments = new RefiPaymentPlan(currentInfo, totalLoanData);
    refiPayments.paymentPlan = refiPayments.calculatePayments();

    // PAYE
    let payeMFSPayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, SEPARATELY, 0.1, true, incomePlan, standardPayments.paymentPlan);
    payeMFSPayments.paymentPlan = payeMFSPayments.calculatePayments();
    let payeMFJPayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, JOINTLY, 0.1, true, incomePlan, standardPayments.paymentPlan);
    payeMFJPayments.paymentPlan = payeMFJPayments.calculatePayments();

    // REPAYE
    let repayePayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, JOINTLY, 0.1, false, incomePlan, standardPayments.paymentPlan);
    repayePayments.paymentPlan = repayePayments.calculatePayments();

    // IBR
    let ibrMFSPayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, SEPARATELY, 0.15, true, incomePlan, standardPayments.paymentPlan);
    ibrMFSPayments.paymentPlan = ibrMFSPayments.calculatePayments();
    let ibrMFJPayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, JOINTLY, 0.15, true, incomePlan, standardPayments.paymentPlan);
    ibrMFJPayments.paymentPlan = ibrMFJPayments.calculatePayments();

    // ICR
    let icrMFSPayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, SEPARATELY, 0.2, false, incomePlan, standardPayments.paymentPlan);
    icrMFSPayments.paymentPlan = icrMFSPayments.calculatePayments();
    let icrMFJPayments = new IncomeBasedPaymentPlan(currentInfo, totalLoanData, JOINTLY, 0.2, false, incomePlan, standardPayments.paymentPlan);
    icrMFJPayments.paymentPlan = icrMFJPayments.calculatePayments();

    return {
      standardPayments: standardPayments.paymentPlan,
      refiPayments: refiPayments.paymentPlan,
      payeMFSPayments: payeMFSPayments.paymentPlan,
      payeMFJPayments: payeMFJPayments.paymentPlan,
      repayePayments: repayePayments.paymentPlan,
      ibrMFSPayments: ibrMFSPayments.paymentPlan,
      ibrMFJPayments: ibrMFJPayments.paymentPlan,
      icrMFSPayments: icrMFSPayments.paymentPlan,
      icrMFJPayments: icrMFJPayments.paymentPlan
    };
  }


  // Generates the repayment schedules and saves them to the state
  generateRepaymentSchedule = (totalLoanData, paymentsData) => {
    // STANDARD
    let standardRepaymentSchedule = new StandardRepaymentSchedule(paymentsData.standardPayments, totalLoanData, paymentsData.standardPayments);
    standardRepaymentSchedule.repaymentSchedule = standardRepaymentSchedule.calculateRepaymentSchedule();

    // REFI
    let refiRepaymentSchedule = new RefiRepaymentSchedule(paymentsData.refiPayments, totalLoanData, paymentsData.standardPayments);
    refiRepaymentSchedule.repaymentSchedule = refiRepaymentSchedule.calculateRepaymentSchedule();

    // PAYE
    let payeMFSRepaymentSchedule = new PayeRepaymentSchedule(paymentsData.payeMFSPayments, totalLoanData, paymentsData.standardPayments);
    payeMFSRepaymentSchedule.repaymentSchedule = payeMFSRepaymentSchedule.calculateRepaymentSchedule();
    let payeMFJRepaymentSchedule = new PayeRepaymentSchedule(paymentsData.payeMFJPayments, totalLoanData, paymentsData.standardPayments);
    payeMFJRepaymentSchedule.repaymentSchedule = payeMFJRepaymentSchedule.calculateRepaymentSchedule();
    console.log("PAYE MFJ ", payeMFJRepaymentSchedule);
    // REPAYE
    let repayeRepaymentSchedule = new RepayeRepaymentSchedule(paymentsData.repayePayments, totalLoanData, paymentsData.standardPayments);
    repayeRepaymentSchedule.repaymentSchedule = repayeRepaymentSchedule.calculateRepaymentSchedule();

    // IBR
    let ibrMFSRepaymentSchedule = new IBRRepaymentSchedule(paymentsData.ibrMFSPayments, totalLoanData, paymentsData.standardPayments);
    ibrMFSRepaymentSchedule.repaymentSchedule = ibrMFSRepaymentSchedule.calculateRepaymentSchedule();
    let ibrMFJRepaymentSchedule = new IBRRepaymentSchedule(paymentsData.ibrMFJPayments, totalLoanData, paymentsData.standardPayments);
    ibrMFJRepaymentSchedule.repaymentSchedule = ibrMFJRepaymentSchedule.calculateRepaymentSchedule();

    // ICR
    let icrMFSRepaymentSchedule = new ICRRepaymentSchedule(paymentsData.icrMFSPayments, totalLoanData, paymentsData.standardPayments);
    icrMFSRepaymentSchedule.repaymentSchedule = icrMFSRepaymentSchedule.calculateRepaymentSchedule();
    let icrMFJRepaymentSchedule = new ICRRepaymentSchedule(paymentsData.icrMFJPayments, totalLoanData, paymentsData.standardPayments);
    icrMFJRepaymentSchedule.repaymentSchedule = icrMFJRepaymentSchedule.calculateRepaymentSchedule();

    return {
      standardRepaymentSchedule: standardRepaymentSchedule.repaymentSchedule,
      refiRepaymentSchedule: refiRepaymentSchedule.repaymentSchedule,
      payeMFSRepaymentSchedule: payeMFSRepaymentSchedule.repaymentSchedule,
      payeMFJRepaymentSchedule: payeMFJRepaymentSchedule.repaymentSchedule,
      repayeRepaymentSchedule: repayeRepaymentSchedule.repaymentSchedule,
      ibrMFSRepaymentSchedule: ibrMFSRepaymentSchedule.repaymentSchedule,
      ibrMFJRepaymentSchedule: ibrMFJRepaymentSchedule.repaymentSchedule,
      icrMFSRepaymentSchedule: icrMFSRepaymentSchedule.repaymentSchedule,
      icrMFJRepaymentSchedule: icrMFJRepaymentSchedule.repaymentSchedule
    }
  }

  // Creates the Year Plan state object once the users has completed the personal, income, and loan forms. Uses this to populate the year plan form.
  generateYearPlan = () => {
    let yearPlan = [];
    for (let i = 0; i < TOTAL_YEARS; i++) {
      // Determining current loop year. First year is current year minus 1.
      let currentYear = parseInt(new Date().getFullYear() - 1) + i;

      // Determining AGI
      let agi = i === 0 ? cloneDeep(parseFloat(this.incomeInfo.lastYearAgi))
        : i === 1 ? cloneDeep(parseFloat(this.incomeInfo.thisYearAgi))
          : i === 2 ? cloneDeep(parseFloat(this.incomeInfo.nextYearAgi))
            : yearPlan[i - 1].agi * YEARLY_INFLATION;
      agi = agi || 0.00;

      // Determining spouse AGI
      let spouseAgi = i === 0
        ? cloneDeep(parseFloat(this.incomeInfo.spouseAgi))
        : yearPlan[i - 1].spouseAgi * YEARLY_INFLATION;
      spouseAgi = spouseAgi || 0.00;

      // Determining family size
      let childrenThisYear = this.personalInfo.futureChildren.filter((year) => (parseInt(year) === parseInt(currentYear))).length;
      let familySize = i === 0 ? (this.personalInfo.isMarried === 'true' ? 2 + parseInt(this.personalInfo.currentNumberOfChildren) : 1 + parseInt(this.personalInfo.currentNumberOfChildren))
        : parseInt(yearPlan[i - 1].familySize) + parseInt(childrenThisYear);

      yearPlan.push({
        year: parseInt(currentYear),
        familySize: parseInt(familySize),
        agi: parseFloat(agi).toFixed(2),
        spouseAgi: parseFloat(spouseAgi).toFixed(2)
      });
    }
    this.yearInfo.yearPlan.push(...yearPlan)
  }

  // Function to compile all the form data to begin calculating their loan information
  calculateAssumptions = () => {
    /* Formats the year plan object into floats and ints */
    const formattedYearInfo = this.yearInfo.yearPlan.map(year => {
      return {
        year: parseInt(year.year) || new Date().getFullYear(),
        familySize: parseInt(year.familySize) || 1,
        agi: parseFloat(year.agi) || 0.00,
        spouseAgi: parseFloat(year.spouseAgi) || 0.00
      }
    })

    const currentInfo = {
      isMarried: this.personalInfo.isMarried.toString() === 'true',
      lastYearAgi: parseFloat(this.incomeInfo.lastYearAgi) || 0,
      thisYearAgi: parseFloat(this.incomeInfo.thisYearAgi) || 0,
      nextYearAgi: parseFloat(this.incomeInfo.nextYearAgi) || 0,
      spouseAgi: parseFloat(this.incomeInfo.spouseAgi) || 0,
      spouseLoans: parseFloat(this.loanInfo.spouseLoanBalance) || 0,
      spouseLoanInterestRate: parseFloat(this.loanInfo.spouseLoanInterestRate) || 0
    }

    let loanData = cloneDeep(this.loanInfo.loans)
    loanData = loanData.map(loan => {
      loan.principal = parseFloat(loan.principal) || 0
      loan.accruedInterest = parseFloat(loan.accruedInterest) || 0
      // In the form, the interest is inputted as a percentage, to calculations use the interest as a decimal
      loan.interest = (parseFloat(loan.interest) / 100) || 0
      loan.totalLoanBalance = (parseFloat(loan.principal) || 0) + (parseFloat(loan.accruedInterest) || 0)
      return loan
    })

    const totalLoanData = new TotalLoans(loanData)
    let paymentsData = this.generatePayments(currentInfo, totalLoanData, formattedYearInfo)
    let repaymentSchedulesData = this.generateRepaymentSchedule(totalLoanData, paymentsData)

    this.loanPaymentData = {
      ...this.loanPaymentData,
      payments: paymentsData,
      repaymentSchedules: repaymentSchedulesData
    }
  }

  generatePaymentEstimateRows = (paymentShedule, header) => {
    let rows = [];
    for (let i = 0; i < 10; i++) {
      let endOfYearData = {
        actualMonthlyPayment: 'N/A',
        loanPrincipal: 'N/A',
        accruingInterest: 'N/A',
        totalDebt: 'N/A',
        totalPayments: 'N/A'
      }
      if (paymentShedule) {
        if (paymentShedule[i]) {
          if (paymentShedule[i][11]) {
            let actualMonthlyPayment = parseFloat(paymentShedule[i][11].actualMonthlyPayment) || 0;
            let loanPrincipal = parseFloat(paymentShedule[i][11].loanPrincipal) || 0;
            let accruingInterest = parseFloat(paymentShedule[i][11].accruingInterest) || 0;
            let totalDebt = loanPrincipal + accruingInterest;
            let totalPayments = parseFloat(paymentShedule[i][11].totalPayments) || 0;
            endOfYearData = {
              actualMonthlyPayment: actualMonthlyPayment.toFixed(2),
              loanPrincipal: loanPrincipal.toFixed(2),
              accruingInterest: accruingInterest.toFixed(2),
              totalDebt: totalDebt.toFixed(2),
              totalPayments: totalPayments.toFixed(2)
            }
          }
        }
      }
      rows.push(
        `<tr key=${header + "-" + (this.personalInfo.isMarried.toString() === 'true' ? 'joint' : 'single') + "-payment-estimate-tr-" + i}>
          <td class="border border-white p-1 text-xs ">End of Year ${i + 1}</td>
          <td class="border border-white p-1 text-xs ">${endOfYearData.actualMonthlyPayment}</td>
          <td class="border border-white p-1 text-xs ">${endOfYearData.loanPrincipal}</td>
          <td class="border border-white p-1 text-xs ">${endOfYearData.accruingInterest}</td>
          <td class="border border-white p-1 text-xs ">${endOfYearData.totalDebt}</td>
          <td class="border border-white p-1 text-xs ">${endOfYearData.totalPayments}</td>
        </tr>`
      );
    }
    return rows;
  }

  formatOutput(target, array) {
    array.forEach(a => {
      target.innerHTML += a
    });
  }

  displayStandardTable() {
    this.formatOutput(this.paymentsEstimateTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.standardRepaymentSchedule, "Standard"))
  }

  displayRefinanceTable() {
    this.formatOutput(this.refinanceTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.refiRepaymentSchedule, "Refinance"))
  }

  displayPayeMfjTable() {
    this.formatOutput(this.payeMfjTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.payeMFJRepaymentSchedule, "PAYE MFJ"))
  }

  displayPayeMfsTable() {
    this.formatOutput(this.payeMfsTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.payeMFSRepaymentSchedule, "PAYE MFS"))
  }

  displayRepayeTable() {
    this.formatOutput(this.repayeTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.repayeRepaymentSchedule, "REPAYE"))
  }

  displayIbrMfsTable() {
    this.formatOutput(this.ibrMfsTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.ibrMFSRepaymentSchedule, "IBR MFS"))
  }

  displayIcrMfjTable() {
    this.formatOutput(this.icrMfjTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.icrMFJRepaymentSchedule, "ICR MFJ"))
  } 

  displayIcrMfsTable() {
    this.formatOutput(this.icrMfsTableTarget,
      this.generatePaymentEstimateRows(this.loanPaymentData.repaymentSchedules.icrMFSRepaymentSchedule, "ICR MFS"))
  }
}
