export default class ClassList {
  constructor(value) {
    if (value) {
      this.classes = value.split(' ')
    }
  }

  add(el) {
    if (!this.classes) return;
    this.classes.forEach(cl => el.classList.add(cl))
  }

  remove(el) {
    if (!this.classes) return;
    this.classes.forEach(cl => el.classList.remove(cl))
  }

  toggle(el) {
    if (!this.classes) return;
    this.classes.forEach(cl => el.classList.toggle(cl))
  }
}