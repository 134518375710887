import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.timer_interval = 10000;

    this.swiper = new Swiper('.swiper', {
      rewind: true,
      slidesPerView: 1,
      spaceBetween: 100,
      pagination: {
        el: '.swiper-pagination',
      },
    });

    this.start_timer();
  }

  start_timer() {
    this.testamony_timer = window.setInterval(() => this.swiper.slideNext(), this.timer_interval);
  }
}
