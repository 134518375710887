import { Toggle } from "tailwindcss-stimulus-components"

export default class extends Toggle {
  toggle(event) {
    if (event.target.getAttribute('type') != 'checkbox' && event.target.getAttribute('type') != 'radio') {
      event.preventDefault();
    }

    this.openValue = !this.openValue
  }

  hide(event) {
    if (event.target.getAttribute('type') != 'checkbox' && event.target.getAttribute('type') != 'radio') {
      event.preventDefault();
    }

    this.openValue = false;
  }

  show(event) {
    if (event.target.getAttribute('type') != 'checkbox' && event.target.getAttribute('type') != 'radio') {
      event.preventDefault();
    }

    this.openValue = true;
  }
}
