import { Controller } from '@hotwired/stimulus'
import ClassList from './utils/ClassList'

export default class extends Controller {
  static targets = ['selectable', 'input']
  static values = {
    selected: String,
    selectedClass: String,
  }

  select(event) {
    if (['checkbox', 'radio'].indexOf(event.target.getAttribute('type')) < 0) {
      event.preventDefault()
    }

    this.selectedValue = event.params.value
  }

  selectedValueChanged() {
    this.selectableTargets.forEach(target => {
      const value = target.getAttribute('data-value')

      const selectedClass = new ClassList(
        !target.getAttribute('data-selected-class') ? this.selectedClassValue : target.getAttribute('data-selected-class')
      )

      const normalClass = new ClassList(target.getAttribute('data-normal-class'))

      if (value === this.selectedValue) {
        selectedClass.add(target)
        normalClass.remove(target)
      } else {
        selectedClass.remove(target)
        normalClass.add(target)
      }

      if (this.inputTargets) {
        this.inputTargets.forEach(t => t.checked = (t.value === this.selectedValue))
      }
    })
  }
}