import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interestRates: Object
  }

  connect() {
  }

  updateInterestRates(event) {
    const selected = event.currentTarget.value;
    const value = this.interestRatesValue[selected];
    const interestRateInput = event.currentTarget.parentNode.parentNode.parentNode.querySelector('.input-interest-rate');
    if (value) {
      interestRateInput.value = String(value);
    } else {
      interestRateInput.value = "";
    }
  }
}
